import React from "react"
import styled, { ThemeProvider } from "styled-components"
import {
  Banner,
  BannerText,
  BannerContent,
  BannerContentFullWidth,
} from "src/components/Banner"
import SEO from "src/components/seo"
import "src/components/common/custom-bluma.scss"
import { PageTitle } from "src/components/Typography"
import { PageSection, SectionTitle } from "src/components/PageSection"
import Modal from "react-modal"
import "src/components/home-page/home-page.scss"
import { AiOutlineClose } from "react-icons/ai"
import { FaPlusCircle } from "react-icons/fa"
import "src/pages/how-it-works.scss"
import { useDeviceType } from "../utils/deviceType"

import left_arrow from "src/images/LeftArrow.svg"
import right_arrow from "src/images/RightArrow.svg"

import TestDrivenImage from "src/images/how_it_works/test_driven.svg"
import FastFeedbackImage from "src/images/how_it_works/fast_feedback.svg"
import DeveloperImage from "src/images/how_it_works/developer.svg"
import FrameImage from "src/images/how_it_works/Frame.svg"

import { ClientHeader } from "src/components/Header"
import Container from "src/components/Container"
import Button from "src/components/Button"
import { rem } from "src/utils/styling"
import { darkTheme } from "src/utils/themes"
import Footer, { LightFooter } from "src/components/Footer"
import { MobileOnlyCarousel } from "src/components/Carousel"
import { useEnvironment } from "src/utils/environment"

const LeftArrow = styled.img`
  width: ${rem(35)}rem;
  vertical-align: middle;
  margin-right: ${rem(5)}rem;
  @media screen and (min-width: 1100px) {
    width: ${rem(90)}rem;
    margin-right: ${rem(20)}rem;
  }
`

const RightArrow = styled.img`
  width: ${rem(20)}rem;
  vertical-align: middle;
  margin-left: ${rem(10)}rem;
  @media screen and (min-width: 1100px) {
    width: ${rem(50)}rem;
    margin-left: ${rem(20)}rem;
  }
`

const SectionTitleSmaller = styled(SectionTitle)`
  color: #080f2a;
  margin: ${rem(30)}rem 0;
  text-align: center;
  strong {
    color: #00b8c5;
  }
  @media screen and (min-width: 1100px) {
    font-size: ${rem(42)}rem;
    text-align: center;
  }
`

const CodeContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column-reverse;

  @media screen and (min-width: 1100px) {
    flex-direction: row;
    margin-top: ${rem(120)}rem;
    align-items: center;
    padding: 0 ${rem(32)}rem;
  }

  &:last-child {
    margin-bottom: ${rem(48)}rem;
    @media screen and (min-width: 1100px) {
      margin-bottom: ${rem(80)}rem;
    }
  }
`

const CodeContainerMiddle = styled(CodeContainer)`
  flex-direction: column;
`

const CodeTextBlock = styled.div``

const TextColumn = styled.div`
  p {
    text-align: center;
  }
  @media screen and (min-width: 1100px) {
    width: ${rem(450)}rem;
    p {
      text-align: left;
    }
  }
`

const TextColumnTwo = styled(TextColumn)`
  // padding: 0 ${rem(30)}rem;
  @media screen and (min-width: 1100px) {
    width: ${rem(550)}rem;
  }
`

const CodeImage = styled.img`
  width: ${rem(150)}rem;
  @media screen and (min-width: 1100px) {
    width: ${rem(250)}rem;
  }
`

const Section = styled.div`
  text-align: center;
`

const SectionContent = styled.div`
  @media screen and (min-width: 1100px) {
    margin: ${rem(80)}rem;
  }
`

const SectionText = styled.p`
  text-align: center;
  font-size: ${rem(16)}rem;
  font-weight: 500;

  span {
    color: #00b8c5;
    font-weight: 800;
  }

  @media screen and (min-width: 1100px) {
    margin: ${rem(150)}rem ${rem(65)}rem 0;
    font-size: ${rem(20)}rem;
  }
`

const SectionTextBold = styled.p`
  text-align: center;
  font-size: ${rem(16)}rem;
  font-weight: 700;
  @media screen and (min-width: 1100px) {
    margin: ${rem(40)}rem ${rem(20)}rem;
    font-size: ${rem(20)}rem;
  }
`

const SectionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${rem(80)}rem 0 ${rem(0)}rem;
`

const SectionButton = styled.p`
  background-color: #00b8c5;
  color: #fff;
  padding: ${rem(20)}rem ${rem(20)}rem;
  font-size: ${rem(16)}rem;
  font-weight: 800;
  border-top-left-radius: ${rem(20)}rem;
  border-bottom-left-radius: ${rem(20)}rem;
  box-shadow: 0px 0px 15px rgba(3, 30, 45, 0.15);
  cursor: pointer;
  @media screen and (min-width: 1100px) {
    padding: ${rem(30)}rem ${rem(60)}rem;
    font-size: ${rem(24)}rem;
  }
`

const SectionLightButton = styled.p`
  background-color: #fff;
  color: #bebfc3;
  padding: ${rem(20)}rem ${rem(20)}rem;
  font-size: ${rem(16)}rem;
  font-weight: 800;
  border-top-right-radius: ${rem(20)}rem;
  border-bottom-right-radius: ${rem(20)}rem;
  box-shadow: 0px 0px 15px rgba(3, 30, 45, 0.15);
  cursor: pointer;
  @media screen and (min-width: 1100px) {
    padding: ${rem(30)}rem ${rem(60)}rem;
    font-size: ${rem(24)}rem;
  }
`

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1100px) {
    margin: ${rem(10)}rem 0 ${rem(10)}rem ${rem(50)}rem;
  }
`

const SectionHeaderText = styled.p`
  font-size: ${rem(18)}rem;
  text-align: center;
  color: #080f2a;
  font-weight: 800;
  width: 185px;
`

const SectionOverView = styled.div`
  margin: ${rem(40)}rem ${rem(40)}rem ${rem(100)}rem;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    margin: ${rem(35)}rem 0 0 ${rem(-120)}rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  @media screen and (min-width: 1100px) and (max-width: 1279px) {
    margin: ${rem(35)}rem 0 0 ${rem(-20)}rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
`

const SectionOverViewText = styled.div`
  p {
    text-align: center;
    color: #080f2a;
    font-size: ${rem(16)}rem;
    font-weight: 800;
  }
  .subtext {
    font-weight: 600;
  }
  @media screen and (min-width: 1100px) {
    width: 150px;
    p {
      text-align: right;
      font-size: ${rem(20)}rem;
      margin-right: ${rem(20)}rem;
    }
  }
`

const SectionOverViewRow = styled.div`
  display: flex !important;
  flex-direction: row;
  justify-content: center !important;
  align-center: center !important;
  margin: ${rem(20)}rem 0 0;
`

const SectionOverViewCard = styled.div`
  flex: 1;
`

const SectionOverViewCardLeft = styled(SectionOverViewCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SectionOverViewCardText = styled.div`
  margin: ${rem(125)}rem ${rem(20)}rem;
  &:first-child {
    margin-top: ${rem(35)}rem;
  }
  h4 {
    color: #080f2a;
    font-size: 16px;
    font-weight: 800;
  }
`

const SectionCard = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 0px 15px rgba(3, 30, 45, 0.15);
  border-radius: 20px;
  padding: ${rem(30)}rem ${rem(20)}rem;
  margin: ${rem(60)}rem auto ${rem(60)}rem;
  @media screen and (min-width: 1100px) {
    width: ${rem(700)}rem;
    padding: ${rem(30)}rem;
    margin: ${rem(120)}rem auto ${rem(120)}rem;
  }
`

const SectionCardRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${rem(20)}rem;
  &:last-child {
    padding-bottom: 0;
  }
  @media screen and (max-width: 400px) {
    display: flex;
    align-items: flex-start;
  }
`

const ColorTag = styled.div`
  height: ${rem(32)}rem;
  width: ${rem(32)}rem;
  margin-right: ${rem(10)}rem;
  padding-right: ${rem(35)}rem;
  @media screen and (min-width: 1100px) {
    margin: 0 ${rem(20)}rem;
  }
`

const TagText = styled.p`
  font-size: ${rem(16)}rem;
  font-weight: 800;
  color: #979797;
  text-align: left;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(18)}rem;
  }
`

const ButtonColumn = styled.div`
  flex-direction: column;
  margin: ${rem(40)}rem 0 0;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
    display: flex;
    align-items: center;
  }
`

const ButtonLeft = styled(Button)`
  min-width: ${rem(270)}rem;
  margin-bottom: ${rem(20)}rem;
  @media screen and (min-width: 1100px) {
    margin-right: ${rem(20)}rem;
    margin-bottom: 0;
    min-width: initial;
  }
`

const ButtonRight = styled(ButtonLeft)`
  @media screen and (min-width: 1100px) {
    margin-right: 0;
    min-width: initial;
  }
`

const Card = styled.div`
  padding: ${rem(40)}rem ${rem(30)}rem;
  position: relative;
  .close-icon {
    position: absolute;
    font-size: ${rem(26)}rem;
    color: #d8d8d8;
    right: ${rem(20)}rem;
    top: ${rem(15)}rem;
    cursor: pointer;
  }
  @media screen and (min-width: 1100px) {
    width: ${rem(700)}rem;
    padding: ${rem(40)}rem;
    .close-icon {
      top: ${rem(25)}rem;
      right: ${rem(30)}rem;
    }
  }
`

const CardHeader = styled.h2`
  font-size: ${rem(24)}rem;
  padding: 0 0 ${rem(15)}rem;
  color: #00b8c5;
  font-weight: 700;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(28)}rem;
    padding: 0 0 ${rem(20)}rem;
  }
`

const CardBody = styled.p`
  font-size: ${rem(18)}rem;
  padding-bottom: ${rem(10)}rem;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(20)}rem;
  }
`

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-40%",
    transform: "translate(-50%, -50%)",
    border: 0,
    borderRadius: 20,
    overflow: "auto",
    padding: 0,
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(8, 15, 42, 0.6)",
    backdropFilter: "blur(8px)",
  },
}

const OnboardingContent = {
  request: {
    title: "Request Review",
    description1:
      "We will set up a few onboarding meetings to get a better sense of what you’re looking to build, and make sure that we have the proper tools to help you do it.",
  },
  code: {
    title: "Code, Functional & Architectural Review",
    description1:
      "We’ll review your codebase to make sure we have the right developers at the right skillset and right experience levels for your job.",
    description2:
      "We’ll also set up the right access & communication channels for our developers to properly plug into your codebase.",
  },
  bid: {
    title: "Bid & Group Formation",
    description1:
      "A bid group is like a virtual team that’s on standby for your needs, with the skill sets and experience levels that you’ll most likely need. We’ll get them up to speed so that when you need a new user story we can get directly to work!",
  },
  stories: {
    title: "Write / Provide User Stories",
    description1:
      "We’ll extract the requirements of your project into user story formats using our predefined templates in order to capture all of the correct details needed to measure the success of developing that user story.",
  },
  confirmation: {
    title: "Confirmation & Bidding",
    description1:
      "We’ll confirm the user stories are ready to go, and then send it out to your bid group for that particular level of expertise and skill set. Your bid group will give you estimates on the amount of work it’ll take, and when they can commit to having it done.",
  },
  development: {
    title: "Development",
    description1:
      "Once a developer gets awarded the user story, they get to work and deliver by the predetermined timeline. You sit back and let the magic happen.",
  },
  bug: {
    title: "QA & Bug Fix",
    description1:
      "Once the developer submits a pull request, a QA engineer will test the code and make sure it meets all of the success criteria. Any place where there’s a bug or something that was in the success criteria but not fully completed, the developer is responsible for fixing and delivering that back at no additional cost to you.",
    description2:
      "In Code Only, you’ll need to do QA yourself but the developer will still be responsible for any fixes needed.",
  },
  acceptance: {
    title: "Acceptance Testing",
    description1:
      "Once you receive working code, you review and ship to production. Violá!",
  },
}

const FlatArrow = styled.div`
  width: 175px;
  height: 80px;
  position: relative;
  background: ${props => props.color};
  margin-right: ${rem(15)}rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    right: -40px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 40px solid ${props => props.color};
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    z-index: 2;
  }

  &:hover {
    background: #080f2a;
    &:before {
      border-left: 40px solid #080f2a;
    }
    .arrow-overlay {
      background-color: #080f2a;
      &:before {
        border-left: 40px solid #080f2a;
      }
    }
  }

  p {
    font-size: ${rem(18)}rem;
    font-weight: 800;
    color: #ffffff;
    margin-left: ${rem(50)}rem;
    position: relative;
    z-index: 5;
  }
`

const LongBentArrow = styled(FlatArrow)`
  width: 555px;
  background: ${props => props.color};

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 40px solid rgb(244, 247, 249);
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
  }
  &:before {
    border-left: 40px solid ${props => props.color};
  }
`

const ShortBentArrow = styled(LongBentArrow)`
  width: 175px;
`

const FlatTopArrow = styled.div`
  background: ${props => props.color};
  width: 180px;
  height: 100px;
  position: relative;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:after {
    border-top: 40px solid ${props => props.color};
    border-left: 90px solid transparent;
    border-right: 90px solid transparent;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    bottom: -40px;
    width: 0;
    z-index: 2;
  }
  p {
    margin-top: ${rem(20)}rem;
    font-size: ${rem(16)}rem;
    font-weight: 800;
    color: #ffffff;
    text-align: center;
    padding: 0 ${rem(20)}rem;
    position: relative;
    z-index: 5;
  }
  .arrow-icon {
    font-size: ${rem(24)}rem;
    color: #fff;
    margin-top: ${rem(10)}rem;
    position: relative;
    z-index: 5;
  }
`

const LongBentVerticalArrow = styled(FlatTopArrow)`
  height: 450px;
  &:before {
    border-top: 40px solid rgb(244, 247, 249);
    border-left: 90px solid transparent;
    border-right: 90px solid transparent;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
  }
`

const ShortBentVerticalArrow = styled(LongBentVerticalArrow)`
  height: 140px;
  p {
    margin-top: ${rem(40)}rem;
  }
`

const MobileContainer = styled.div`
  .slick-slider {
    .slick-dots {
      top: 100px;
      bottom: initial;
    }
  }
`

const HowItWorks = ({ location }) => {
  const [showFullService, setShowFullService] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [modalContent, setModalContent] = React.useState({})
  const toggleCodeOnly = () => setShowFullService(false)
  const toggleFullService = () => setShowFullService(true)
  const showModal = key => {
    const value = OnboardingContent[key]
    setModalContent(value)
    setIsOpen(!isOpen)
  }
  const deviceType = useDeviceType()
  const environment = useEnvironment()
  const templateLink =
    environment == "staging"
      ? "https://forms.gle/bmUXcdjjoAFJDLJn9"
      : "https://forms.gle/ZYfw7FreBfw1dBvWA"
  const jiraPluginLink = "https://forms.gle/qXHvm1Tk2sJoZCoR6"

  return (
    <ThemeProvider theme={darkTheme}>
      <Banner>
        <ClientHeader />
        <Container>
          <SEO
            title="The Archimydes Methodology - How it works"
            description="Centered on three core principles, the Archimydes methodology is the pillar that allows us to deliver quality code faster, and at a more affordable price than anything else out there."
            location={location}
          />

          <BannerContent className="has-text-centered">
            <BannerContentFullWidth>
              <PageTitle>
                <LeftArrow
                  src={left_arrow}
                  alt="Out-task Stories"
                  className="arrow-image-left"
                />
                The <em>Archimydes</em> Methodology
                <RightArrow
                  src={right_arrow}
                  alt="Out-task Stories"
                  className="arrow-image-right"
                />
              </PageTitle>
              <BannerText>
                The Archimydes methodology is the pillar that allows us to
                deliver quality code faster, and at a more affordable price than
                anything else out there.
              </BannerText>
            </BannerContentFullWidth>
          </BannerContent>
        </Container>
      </Banner>
      <PageSection
        style={{
          backgroundColor: "#F4F7F9",
          overflow: "hidden",
          paddingBottom: 0,
        }}
      >
        <Container style={{ paddingBottom: "4rem", overflow: "hidden" }}>
          <SectionTitleSmaller>
            Our <strong>3 core principles</strong>
          </SectionTitleSmaller>
          <div className="code-background">
            <div
              role="presentation"
              className="arrow arrow-1"
              style={{ top: 450 }}
            ></div>
            <div
              role="presentation"
              className="arrow arrow-2"
              style={{ bottom: "-70rem" }}
            ></div>
            <div
              role="presentation"
              className="arrow arrow-3"
              style={{ display: "initial", left: "3rem", top: "145rem" }}
            ></div>
            <div>
              <CodeContainer>
                <CodeTextBlock>
                  <TextColumn>
                    <h3 className="code-section-subtitle">
                      Test-driven <strong>requirements</strong>
                    </h3>

                    <p className="code-section-text">
                      We use testable success criteria for each user story so
                      success is measurable and digestible.
                    </p>
                  </TextColumn>
                </CodeTextBlock>
                <div className="code-section-image-wrapper">
                  <CodeImage src={TestDrivenImage} alt="Scale On Demand" />
                </div>
              </CodeContainer>

              <CodeContainerMiddle>
                <div className="code-section-image-wrapper">
                  <CodeImage src={FastFeedbackImage} alt="Scale On Demand" />
                </div>
                <CodeTextBlock>
                  <TextColumn>
                    <h3 className="code-section-subtitle">
                      <strong>Fast feedback </strong>loops
                    </h3>
                    <p className="code-section-text">
                      We divide work into 1-8 hour increments of development in
                      order to keep the pace of deployment high and get quick
                      feedback.
                    </p>
                  </TextColumn>
                </CodeTextBlock>
              </CodeContainerMiddle>

              <CodeContainer>
                <CodeTextBlock>
                  <TextColumn>
                    <h3 className="code-section-subtitle">
                      <strong>Developer </strong> Matching
                    </h3>
                    <p className="code-section-text">
                      We match the right developer to tasks of the right
                      complexity so you don’t waste money on experts working on
                      simple tasks.
                    </p>
                  </TextColumn>
                </CodeTextBlock>
                <div className="code-section-image-wrapper">
                  <CodeImage
                    src={DeveloperImage}
                    alt="Effortless Integration"
                  />
                </div>
              </CodeContainer>
            </div>
          </div>

          <Section>
            <SectionContent>
              <SectionText>
                In order to help you and your team interact with our systems
                effectively, we have dedicated customer success managers that
                help you onboard into our system and see the magic happen
                upfront.
              </SectionText>
              <SectionTextBold>
                Here’s what you can expect when working with Archimydes:
              </SectionTextBold>
              <SectionButtonContainer>
                <SectionButton
                  onClick={toggleCodeOnly}
                  style={
                    showFullService
                      ? { backgroundColor: "#fff", color: "#BEBFC3" }
                      : { backgroundColor: "#00B8C5" }
                  }
                >
                  Code Only
                </SectionButton>
                <SectionLightButton
                  onClick={toggleFullService}
                  style={
                    showFullService
                      ? { backgroundColor: "#080F2A", color: "#fff" }
                      : { backgroundColor: "#fff" }
                  }
                >
                  Full Service
                </SectionLightButton>
              </SectionButtonContainer>
            </SectionContent>
          </Section>
          {deviceType === "mobile" ? (
            <>
              {showFullService ? (
                <MobileFullService showModal={showModal} />
              ) : (
                <MobileCodeOnly showModal={showModal} />
              )}
            </>
          ) : (
            <>
              {showFullService ? (
                <FullService showModal={showModal} />
              ) : (
                <CodeOnly showModal={showModal} />
              )}
            </>
          )}
          <Section>
            <SectionContent>
              <SectionTitleSmaller>
                Explore &amp; Leverage <strong>our Methodology</strong>
              </SectionTitleSmaller>
              <CodeContainerMiddle>
                <div className="code-section-image-wrapper">
                  <CodeImage src={FrameImage} alt="Our Methodology" />
                </div>
                <CodeTextBlock>
                  <TextColumnTwo>
                    <p className="code-section-text">
                      Our user story templates are open source - try them for
                      yourself! Use this link to download our templates and get
                      your team working at the same pace we do!
                    </p>
                  </TextColumnTwo>
                  <ButtonColumn>
                    <a href={templateLink} target="_blank">
                      <ButtonLeft type="solid" color="secondary">
                        Download templates
                      </ButtonLeft>
                    </a>
                    <a href={jiraPluginLink} target="_blank">
                      <ButtonRight type="solid" color="secondary">
                        Install Jira Plugin
                      </ButtonRight>
                    </a>
                  </ButtonColumn>
                </CodeTextBlock>
              </CodeContainerMiddle>
            </SectionContent>
          </Section>
        </Container>
      </PageSection>
      <LightFooter />
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
      >
        <Card>
          <AiOutlineClose
            className="close-icon"
            onClick={() => setIsOpen(false)}
          />
          <CardHeader>{modalContent && modalContent.title}</CardHeader>
          <CardBody>{modalContent && modalContent.description1}</CardBody>
          <CardBody>{modalContent && modalContent.description2}</CardBody>
        </Card>
      </Modal>
    </ThemeProvider>
  )
}

export default HowItWorks

const CodeOnly = props => (
  <>
    <Section>
      <SectionHeader>
        <SectionHeaderText>Day 1</SectionHeaderText>
        <SectionHeaderText>Day 2</SectionHeaderText>
        <SectionHeaderText>Day 3</SectionHeaderText>
        <SectionHeaderText>Day 4</SectionHeaderText>
        <SectionHeaderText>Day 5</SectionHeaderText>
      </SectionHeader>

      <SectionOverView>
        <SectionOverViewText>
          <p>Onboarding</p>
          <p className="subtext">Week 1</p>
        </SectionOverViewText>
        <FlatArrow color={"#00B8C5"} onClick={() => props.showModal("request")}>
          <p>Request Review</p>
        </FlatArrow>
        <LongBentArrow
          color={"#00B8C5"}
          onClick={() => props.showModal("code")}
        >
          <p>Code, Functional &amp; Architecture Review</p>
        </LongBentArrow>
        <ShortBentArrow
          color={"#00B8C5"}
          onClick={() => props.showModal("bid")}
        >
          <p>Bid Group Formation</p>
        </ShortBentArrow>
      </SectionOverView>

      <SectionOverView>
        <SectionOverViewText>
          <p>Ongoing</p>
          <p className="subtext">Week 2</p>
        </SectionOverViewText>
        <FlatArrow color={"#FBBC62"} onClick={() => props.showModal("stories")}>
          <p>Write/Provide Stories</p>
        </FlatArrow>
        <ShortBentArrow
          color={"#FBBC62"}
          onClick={() => props.showModal("confirmation")}
        >
          <p>Confirmation &amp; Bidding</p>
        </ShortBentArrow>
        <ShortBentArrow
          color={"#00B8C5"}
          onClick={() => props.showModal("development")}
        >
          <p>Development</p>
        </ShortBentArrow>
        <ShortBentArrow
          color={"#FBBC62"}
          onClick={() => props.showModal("bug")}
        >
          <p>QA &amp; Bug Fix</p>
          <div className="arrow-overlay"></div>
        </ShortBentArrow>
        <ShortBentArrow
          color={"#00C5A1"}
          onClick={() => props.showModal("acceptance")}
        >
          <p>Acceptance Testing</p>
        </ShortBentArrow>
      </SectionOverView>
    </Section>

    <Section>
      <SectionContent>
        <SectionText style={{ marginTop: "5rem" }}>
          In Code Only, we’ll spend 2 weeks giving you full service to make sure
          you’re getting <span>the most out of our system.</span>
        </SectionText>
      </SectionContent>
    </Section>

    <Section>
      <SectionCard>
        <SectionCardRow>
          <ColorTag style={{ background: "#00B8C5" }} />
          <TagText>Archimydes Responsibility</TagText>
        </SectionCardRow>
        <SectionCardRow>
          <ColorTag style={{ background: "#FBBC62" }} />
          <TagText>
            Customer Responsibility Post Enablement in Code Only Mode
          </TagText>
        </SectionCardRow>
        <SectionCardRow>
          <ColorTag style={{ background: "#00C5A1" }} />
          <TagText>Customer Responsibility</TagText>
        </SectionCardRow>
      </SectionCard>
    </Section>
  </>
)

const FullService = props => (
  <>
    <Section>
      <SectionHeader>
        <SectionHeaderText>Day 1</SectionHeaderText>
        <SectionHeaderText>Day 2</SectionHeaderText>
        <SectionHeaderText>Day 3</SectionHeaderText>
        <SectionHeaderText>Day 4</SectionHeaderText>
        <SectionHeaderText>Day 5</SectionHeaderText>
      </SectionHeader>

      <SectionOverView>
        <SectionOverViewText>
          <p>Onboarding</p>
          <p className="subtext">Week 1</p>
        </SectionOverViewText>
        <FlatArrow color={"#00B8C5"} onClick={() => props.showModal("request")}>
          <p>Request Review</p>
        </FlatArrow>
        <LongBentArrow
          color={"#00B8C5"}
          onClick={() => props.showModal("code")}
        >
          <p>Code, Functional &amp; Architecture Review</p>
        </LongBentArrow>
        <ShortBentArrow
          color={"#00B8C5"}
          onClick={() => props.showModal("bid")}
        >
          <p>
            Bid Group <br />
            Formation
          </p>
        </ShortBentArrow>
      </SectionOverView>

      <SectionOverView>
        <SectionOverViewText>
          <p>Ongoing</p>
          <p className="subtext">Week 2</p>
        </SectionOverViewText>
        <FlatArrow color={"#00B8C5"} onClick={() => props.showModal("stories")}>
          <p>Write/Provide Stories</p>
        </FlatArrow>
        <ShortBentArrow
          color={"#00B8C5"}
          onClick={() => props.showModal("confirmation")}
        >
          <p>Confirmation &amp; Bidding</p>
        </ShortBentArrow>
        <ShortBentArrow
          color={"#00B8C5"}
          onClick={() => props.showModal("development")}
        >
          <p>Development</p>
        </ShortBentArrow>
        <ShortBentArrow
          color={"#00B8C5"}
          onClick={() => props.showModal("bug")}
        >
          <p>QA &amp; Bug-Fix</p>
        </ShortBentArrow>
        <ShortBentArrow
          color={"#00C5A1"}
          onClick={() => props.showModal("acceptance")}
        >
          <p>Acceptance Testing</p>
        </ShortBentArrow>
      </SectionOverView>
    </Section>

    <Section>
      <SectionCard>
        <SectionCardRow>
          <ColorTag style={{ background: "#00B8C5" }} />
          <TagText>Archimydes Responsibility</TagText>
        </SectionCardRow>
        <SectionCardRow>
          <ColorTag style={{ background: "#00C5A1" }} />
          <TagText>Customer Responsibility</TagText>
        </SectionCardRow>
      </SectionCard>
    </Section>
  </>
)

const MobileCodeOnly = props => (
  <MobileContainer>
    <MobileOnlyCarousel methodology={true}>
      <div>
        <SectionOverView>
          <SectionOverViewText>
            <p>Onboarding</p>
            <p className="subtext">Week 1</p>
          </SectionOverViewText>
        </SectionOverView>
        <SectionOverViewRow>
          <SectionOverViewCardLeft>
            <FlatTopArrow color={"#00B8C5"}>
              <p>Request Review</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("request")}
              />
            </FlatTopArrow>
            <LongBentVerticalArrow color={"#00B8C5"}>
              <p>Code, Functional &amp; Architecture Review</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("code")}
              />
            </LongBentVerticalArrow>
            <ShortBentVerticalArrow color={"#00B8C5"}>
              <p>Bid Group Formation</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("bid")}
              />
            </ShortBentVerticalArrow>
          </SectionOverViewCardLeft>
          <SectionOverViewCard>
            <SectionOverViewCardText>
              <h4>Day 1</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 2</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 3</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 4</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 5</h4>
            </SectionOverViewCardText>
          </SectionOverViewCard>
        </SectionOverViewRow>
      </div>

      <div>
        <SectionOverView>
          <SectionOverViewText>
            <p>Ongoing</p>
            <p className="subtext">Week 2</p>
          </SectionOverViewText>
        </SectionOverView>
        <SectionOverViewRow>
          <SectionOverViewCardLeft>
            <FlatTopArrow color={"#FBBC62"}>
              <p>Write/Provide Stories</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("stories")}
              />
            </FlatTopArrow>
            <ShortBentVerticalArrow color={"#FBBC62"}>
              <p>Confirmation &amp; Bidding</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("confirmation")}
              />
            </ShortBentVerticalArrow>
            <ShortBentVerticalArrow color={"#00B8C5"}>
              <p>Development</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("development")}
              />
            </ShortBentVerticalArrow>
            <ShortBentVerticalArrow color={"#00B8C5"}>
              <p style={{ marginLeft: 20 }}>QA &amp; Bug-Fix</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("bug")}
              />
              <div className="mobile-arrow-overlay"></div>
            </ShortBentVerticalArrow>
            <ShortBentVerticalArrow color={"#00C5A1"}>
              <p>Acceptance Testing</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("acceptance")}
              />
            </ShortBentVerticalArrow>
          </SectionOverViewCardLeft>
          <SectionOverViewCard>
            <SectionOverViewCardText>
              <h4>Day 1</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 2</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 3</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 4</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 5</h4>
            </SectionOverViewCardText>
          </SectionOverViewCard>
        </SectionOverViewRow>
      </div>
    </MobileOnlyCarousel>
    <Section>
      <SectionContent>
        <SectionText>
          In Code Only, we’ll spend 2 weeks giving you full service to make sure
          you’re getting <span>the most out of our system.</span>
        </SectionText>
      </SectionContent>
    </Section>
    <Section>
      <SectionCard>
        <SectionCardRow>
          <ColorTag style={{ background: "#00B8C5" }} />
          <TagText>Archimydes Responsibility</TagText>
        </SectionCardRow>
        <SectionCardRow>
          <ColorTag style={{ background: "#FBBC62" }} />
          <TagText>
            Customer Responsibility Post Enablement in Code Only Mode
          </TagText>
        </SectionCardRow>
        <SectionCardRow>
          <ColorTag style={{ background: "#00C5A1" }} />
          <TagText>Customer Responsibility</TagText>
        </SectionCardRow>
      </SectionCard>
    </Section>
  </MobileContainer>
)

const MobileFullService = props => (
  <MobileContainer>
    <MobileOnlyCarousel methodology={true}>
      <div>
        <SectionOverView>
          <SectionOverViewText>
            <p>Onboarding</p>
            <p className="subtext">Week 1</p>
          </SectionOverViewText>
        </SectionOverView>
        <SectionOverViewRow>
          <SectionOverViewCardLeft>
            <FlatTopArrow color={"#00B8C5"}>
              <p>Request Review</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("request")}
              />
            </FlatTopArrow>
            <LongBentVerticalArrow color={"#00B8C5"}>
              <p>Code, Functional &amp; Architecture Review</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("code")}
              />
            </LongBentVerticalArrow>
            <ShortBentVerticalArrow color={"#00B8C5"}>
              <p>Bid Group Formation</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("bid")}
              />
            </ShortBentVerticalArrow>
          </SectionOverViewCardLeft>
          <SectionOverViewCard>
            <SectionOverViewCardText>
              <h4>Day 1</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 2</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 3</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 4</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 5</h4>
            </SectionOverViewCardText>
          </SectionOverViewCard>
        </SectionOverViewRow>
      </div>

      <div>
        <SectionOverView>
          <SectionOverViewText>
            <p>Ongoing</p>
            <p className="subtext">Week 2</p>
          </SectionOverViewText>
        </SectionOverView>
        <SectionOverViewRow>
          <SectionOverViewCardLeft>
            <FlatTopArrow color={"#00B8C5"}>
              <p>Write/Provide Stories</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("stories")}
              />
            </FlatTopArrow>
            <ShortBentVerticalArrow color={"#00B8C5"}>
              <p>Confirmation &amp; Bidding</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("confirmation")}
              />
            </ShortBentVerticalArrow>
            <ShortBentVerticalArrow color={"#00B8C5"}>
              <p>Development</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("development")}
              />
            </ShortBentVerticalArrow>
            <ShortBentVerticalArrow color={"#00B8C5"}>
              <p>QA &amp; Bug Fix</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("bug")}
              />
            </ShortBentVerticalArrow>
            <ShortBentVerticalArrow color={"#00C5A1"}>
              <p>Acceptance Testing</p>
              <FaPlusCircle
                className="arrow-icon"
                onClick={() => props.showModal("acceptance")}
              />
            </ShortBentVerticalArrow>
          </SectionOverViewCardLeft>
          <SectionOverViewCard>
            <SectionOverViewCardText>
              <h4>Day 1</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 2</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 3</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 4</h4>
            </SectionOverViewCardText>
            <SectionOverViewCardText>
              <h4>Day 5</h4>
            </SectionOverViewCardText>
          </SectionOverViewCard>
        </SectionOverViewRow>
      </div>
    </MobileOnlyCarousel>
    <Section>
      <SectionCard>
        <SectionCardRow>
          <ColorTag style={{ background: "#00B8C5" }} />
          <TagText>Archimydes Responsibility</TagText>
        </SectionCardRow>
        <SectionCardRow>
          <ColorTag style={{ background: "#00C5A1" }} />
          <TagText>Customer Responsibility</TagText>
        </SectionCardRow>
      </SectionCard>
    </Section>
  </MobileContainer>
)
