import styled from "styled-components"
import { rem } from "../utils/styling"

export const PageTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: ${rem(36)}rem;
  line-height: 1.3;
  color: ${props => props.theme.banner.color};
  margin-top: 0;
  max-width: ${rem(268)}rem;
  margin-left: auto;
  margin-right: auto;

  > em {
    font-style: normal;
    color: #00b8c5;
  }
  @media screen and (min-width: 1100px) {
    font-size: ${rem(60)}rem;
    max-width: initial;
    margin-left: 0;
    margin-right: 0;
    line-height: 1.2;
  }
`
